<template>
    <div class="container fieldList">
      <template v-if="paginated !== ''">

      <h2>{{ title }}</h2>
      <table class="table table-striped">
        <thead>
           <tr >
             <template v-for="(list, key) in paginated.data[0]">
            <td v-if="key != 'label' && key != 'type'" :key="key">{{key}}</td>
            </template>

          </tr>
        </thead>
        <tbody>
          <tr v-for="(list, key) in paginated.data" :key="key">
            <template v-for="(lab, key_) in list">
                <td v-if="key_ != 'label' && key_ != 'type' && key_ != 'split_area' && key_ != 'paragraph_ids'" :key="key_">{{lab}}</td>
                <td v-if="key_ === 'split_area'" :key="key_">
                  X: {{lab['x']}}<br>
                  y: {{lab['y']}}
                </td>
                <td v-if="key_ === 'paragraph_ids'" :key="key_">
                  <template v-for="(merged,key__) in lab" >
                      <span :key="key__">{{merged}}<br></span>
                  </template>
                </td>
           </template>
          </tr>
        </tbody>
      </table>


       <div class="row" v-if="paginationType === 'fast'">
        <div class="pagination">
          <a
            v-for="(page, key) in pages"
            :key="key"
            :class="`${page.status}`"
            @click="changePage(page.page)"
            >{{ page.page }}</a
          >
        </div>
      </div>
      <div class="row pages" v-if="paginationType === 'long'">
        <div class="col-md-2">
          <div class="pagination">
            <a :class="`${pages[0].status}`" @click="previousPage()">{{
              pages[0].page
            }}</a>
            <a :class="`${pages[1].status}`" @click="nextPage()">{{
              pages[1].page
            }}</a>
          </div>
        </div>
        <div class="col-md-2">
          <h3>{{ pageLongIndex }} de {{ totalPages }} páginas</h3>
        </div>
        <div class="col-md-4">
          <input
            class="form-group"
            type="text"
            v-model="searchPage"
            placeholder="Procurar página..."
            @change="inputChange(searchPage)"
          />
        </div>
      </div>
      
      </template>
    </div>

</template>

<script type="text/javascript">
import { ListAnalyticsClassificationMixin } from "./list-analytics-classification.mixin";
export default {
  methods: {},
  name: "ListAnalyticsClassification",
  mixins: [ListAnalyticsClassificationMixin],
};
</script>

<style src="./list-analytics-classification.less" lang="less" />
