import {
    getMatriculas_an,
    getMatriculas_andata,
} from "../../services/analytics.services";
import {
    Classificationchart,
    Matriculachart,
    Userschart,
} from "../../factories/staticGraphicFactory";
import moment from "moment";
export const MatriculasMixin = {
    data() {
        return {
            loading: true,
            matriculas: [],
            matriculasLabels: [],
            userLabels: [],
            matriculasCount: [],
            sendProcess: [],
            numbermatricula: "",
            dashChanges: false,
            usersData: {},
            matriculasData: {},
            _matriculasData: {},
            chartData: {},
            matriculachartData: {},
            userschartData: {},
            changesBytype: false,
            _changesBytype: {},
            matriculaChart: false,
            usersChart: false,
            classificationChart: false,
            updated: false,
            dateRange: {
                startDate: new Date(),
                endDate: new Date(),
            },
        };
    },
    created() {
        this.getData();
    },
    mounted() {},
    filters: {},
    watch: {},
    computed: {},
    methods: {
        async getData() {
            this.loading = true;
            try {
                this.matriculasLabels = [];
                let { data } = await getMatriculas_an({});
                this.matriculas = this.$duplicate(data.data.matriculas).map((val) => {
                    return JSON.parse(val[0]);
                })
                this.sendProcess = this.$duplicate(data.data.send_process).map(val => { return JSON.parse(val[0]) })

                this.matriculasChanges(this.$duplicate(data.data.matriculas));
                this.matriculas = this.matriculas
                    .filter((val) => {
                        return val;
                    })
                    .map((val) => {
                        if (val.paragraph_id != undefined) {
                            val.paragraph_id = val.paragraph_id.split("/")[0];
                            for (let i in val) {
                                if (i === "paragraph_ids") {
                                    val[i] = val[i].map((val_) => {
                                        val_ = val_.split("/")[0];
                                        return val_;
                                    });
                                }
                            }
                            val.pages = val.pages || 0;
                        }
                        return val;
                    });
                this.matriculaschangesbyType(this.$duplicate(this.matriculas));
                this.matriculasLabels = this.getLabels()
                this.userLabels = this.getUserlabels()
                this.mountLabels()
                this.mountProcess()
                this.mountData();
                this.loading = false
            } catch (e) {
                this.loading = false
                this.$tokentest(e.response.status)
                this.$toast.error('Ocorreu um erro ao carregar os dados')
                console.log(e);
            }
        },
        getIds() {
            let matricula_ids = this.$duplicate(this.matriculas);
            matricula_ids = matricula_ids
                .map((val) => {
                    return val.matricula_id;
                })
                .filter((val, index, self) => {
                    return self.indexOf(val) === index;
                });
            return matricula_ids;
        },
        getLabels() {
            let labels = this.$duplicate(this.matriculas);
            labels = labels
                .map((val) => {
                    return val.label;
                })
                .filter((val, index, self) => {
                    return self.indexOf(val) === index;
                });
            return labels;
        },
        getUserlabels() {
            let userlabels = this.$duplicate(this.sendProcess)
            userlabels = userlabels.map(val => {
                    return val.user
                })
                .filter((val, index, self) => {
                    return self.indexOf(val) === index
                })
            return userlabels;
        },
        matriculaschangesbyType(matricula) {
            this.changesBytype = {}
            let ids = this.getIds();
            let modtype = this.$duplicate(
                matricula
                .map((val) => {
                    return val.label;
                })
                .filter((val, index, self) => {
                    return self.indexOf(val) === index;
                })
            );
            for (let i in ids) {
                this.changesBytype[ids[i]] = {};
                this.changesBytype[ids[i]].enabled = false;
                for (let j in modtype) {
                    this.changesBytype[ids[i]][modtype[j]] = {};
                    let count = matricula.filter((val) => {
                        if (val.matricula_id == ids[i] && val.label == modtype[j]) {
                            return val;
                        }
                    }).length;
                    let pages =
                        matricula
                        .map((val) => {
                            if (val.matricula_id == ids[i] && val.label == modtype[j]) {
                                if (val.pages === undefined) {
                                    val.pages = 0;
                                }
                                return val.pages;
                            }
                        })
                        .filter((val) => {
                            return val != undefined;
                        })[0] || 0;
                    this.changesBytype[ids[i]][modtype[j]] = {
                        Tipo: modtype[j],
                        Alterações: count,
                        Páginas: pages,
                        Média: !isNaN((count / pages).toFixed(2)) ? (count / pages).toFixed(2) : 'No data',
                    };
                }
            }
            this._changesBytype = this.$duplicate(this.changesBytype);
        },
        matriculasChanges(matricula) {
            this.matriculachartData.labels = [];
            this.matriculachartData.total = [];
            this.matriculachartData.colors = [];
            this.matriculasCount = [];
            matricula = matricula.map((val) => {
                return JSON.parse(val[0]);
            });
            let filteredmatriculas = this.$duplicate(
                matricula
                .map((val) => {
                    return val.matricula_id;
                })
                .filter((val, index, self) => {
                    return self.indexOf(val) === index;
                })
            );
            for (let i in filteredmatriculas) {
                let alteracoes = matricula.filter((val) => {
                    return val.matricula_id === filteredmatriculas[i];
                }).length;
                let numberpages =
                    matricula
                    .map((val) => {
                        if (val.matricula_id === filteredmatriculas[i]) {
                            return val.pages;
                        }
                    })
                    .filter((val) => {
                        return val != null;
                    })[0] || 0;
                this.matriculasCount.push({
                    matricula_id: filteredmatriculas[i],
                    Alterações: alteracoes,
                    "Número de páginas": numberpages,
                    "Média de Alterações por página":
                        (alteracoes / numberpages).toFixed(2) || "Not pages",
                });
                this.matriculachartData.labels.push(filteredmatriculas[i]);
                this.matriculachartData.total.push(
                    matricula.filter((val) => {
                        return val.matricula_id === filteredmatriculas[i];
                    }).length
                );
                this.matriculachartData.colors.push(
                    `#${Math.floor(Math.random() * 16777215).toString(16)}`
                );
            }
            this.mountmatriculasChart();
        },
        mountData() {
            this.chartData.labels = [];
            this.chartData.total = [];
            for (let i in this.matriculasLabels) {
                this.matriculasData[this.matriculasLabels[i]] = {
                    list: this.matriculas.filter((val) => {
                        return val.label === this.matriculasLabels[i];
                    }),
                    count: this.matriculas.filter((val) => {
                        return val.label === this.matriculasLabels[i];
                    }).length,
                };
                this.chartData.labels.push(this.matriculasLabels[i]);
                this.chartData.total.push(
                    this.matriculasData[this.matriculasLabels[i]].count
                );
            }
            this._matriculasData = this.$duplicate(this.matriculasData);
            this.mountChart();
        },

        mountProcess() {
            this.userschartData.labels = []
            this.userschartData.intLabels = []
            this.userschartData.colors = []
            this.userschartData.labels = this.userLabels
            this.userschartData.total = []
            for (let i in this.userLabels) {
                this.usersData[this.userLabels[i]] = {}

                this.usersData[this.userLabels[i]]['Analised'] =
                    this.sendProcess
                    .filter(val => { return val.user === this.userLabels[i] })
                    .map(val => { return val.actions })
                    .length

                this.usersData[this.userLabels[i]]['Actions'] =
                    this.sendProcess
                    .filter(val => { return val.user === this.userLabels[i] })
                    .map(val => { return val.actions })
                    .reduce((ac, val) => { return val + ac })

                this.usersData[this.userLabels[i]]['Pages'] =
                    this.sendProcess
                    .filter(val => { return val.user === this.userLabels[i] })
                    .map(val => { if (val.pages !== 0) { return val.pages } })
                    .reduce((ac, val) => { return val + ac })

                this.usersData[this.userLabels[i]]['Median'] =
                    (this.usersData[this.userLabels[i]].actions / this.usersData[this.userLabels[i]].pages).toFixed(2)

                this.usersData[this.userLabels[i]]['Matrículas'] =
                    this.sendProcess
                    .filter(val => { return val.user === this.userLabels[i] })
                    .map((val) => { return val.matricula_id })
                    .filter((val, index, self) => { return self.indexOf(val) === index })
                    .length

                let chartTotals = this.usersData[this.userLabels[i]]
                this.userschartData.total.push({
                    'Actions': chartTotals['Actions'],
                    'Pages': chartTotals['Pages'],
                    'Matrículas': chartTotals['Matrículas']
                })


            }
            this.userschartData.intLabels = ['Actions', 'Pages', 'Matrículas']
            this.mountusersChart()

        },

        mountLabels() {
            this.dashChanges = new Object()
            let matriculas = this.$duplicate(this.matriculas)
            let matriculanumbers = matriculas.map(val => { return val.matricula_id }).filter((val, index, self) => { return self.indexOf(val) === index })
            let total = []
            for (let i in matriculanumbers) {
                total.push(
                    matriculas.map(val => { if (val.matricula_id === matriculanumbers[i]) { return val.pages } }).filter(val => { return val })[0]
                )
            }
            total = total.reduce((ac, val) => { return ac + val })
            this.dashChanges.changes = matriculas.length
            this.dashChanges.total = total
            this.dashChanges.media = (matriculas.length / total).toFixed(2)
            console.log(this.dashChanges)
        },
        resetMatricula() {
            this.changesBytype = this.$duplicate(this._changesBytype);
            this.$forceUpdate();
        },
        filterMatricula(number) {
            let list = this.$duplicate(this._changesBytype)
            for (let i in list) {
                if (i !== number) {
                    delete list[i];
                }
            }
            this.changesBytype = list
            this.$forceUpdate();
        },
        mountChart() {
            let data = [this.chartData.labels, this.chartData.total];
            this.classificationChart = new Classificationchart(
                data,
                "Classification"
            );
        },
        mountmatriculasChart() {
            let data = [
                this.matriculachartData.labels,
                this.matriculachartData.total,
                this.matriculachartData.colors,
            ];
            this.matriculaChart = new Matriculachart(data, "Matrículas");
        },
        mountusersChart() {
            let data = [
                this.userschartData.labels,
                this.userschartData.intLabels,
                this.userschartData.total,

            ];
            this.usersChart = new Userschart(data, 'Users')
            console.log(this.usersChart)
        },
        async getbyPeriod() {
            let dat = {
                startDate: "",
                endDate: "",
            };
            dat.startDate = moment(this.dateRange.startDate)
                .locale("en")
                .format("YYYY-MM-DD");
            dat.endDate = moment(this.dateRange.endDate)
                .locale("en")
                .format("YYYY-MM-DD");
            this.loading = true;
            this.matriculasLabels = [];
            try {
                let { data } = await getMatriculas_andata(dat);

                this.matriculasData = new Object();
                this._matriculasData = new Object();
                this.matriculachartData = new Object();
                this.userschartData = new Object();
                this.matriculasChanges(this.$duplicate(data.data.matriculas));
                this.matriculas = this.$duplicate(data.data.matriculas).map((val) => {
                    return JSON.parse(val[0]);
                });
                this.sendProcess = this.$duplicate(data.data.send_process).map(val => { return JSON.parse(val[0]) })

                this.matriculas = this.matriculas
                    .filter((val) => {
                        return val;
                    })
                    .map((val) => {
                        if (val.paragraph_id != undefined) {
                            val.paragraph_id = val.paragraph_id.split("/")[0];
                            for (let i in val) {
                                if (i === "paragraph_ids") {
                                    val[i] = val[i].map((val_) => {
                                        val_ = val_.split("/")[0];
                                        return val_;
                                    });
                                }
                            }
                            val.pages = val.pages || 0;
                        }
                        return val;
                    });
                this.matriculasLabels = this.getLabels();
                this.userLabels = this.getUserlabels()
                this.mountProcess()
                this.matriculaschangesbyType(this.$duplicate(this.matriculas));
                this.mountData()
                this.mountLabels()
                this.loading = false
                this.updated = true;
            } catch (e) {
                console.log(e);
                this.$tokentest(e.response.status)
                this.$toast.error('Ocorreu um erro ao carregar os dados')
                this.loading = false;
            }
        },
    },
};