<template>
  <div class="container-fluid matriculas">
    <LoaderComponent v-if="loading" />
    <div class="container">
      <div class="row justify-content-center">
        <h1>Classificador Real Time</h1>
      </div>
      <div class="row datepicker">
        <date-range-picker
          v-model="dateRange"
          @update="getbyPeriod()"
        ></date-range-picker>
      </div>
    <div class="row justify-content-around dash" v-if="dashChanges">
      <div class="dash__box" >
        <h2>Alterações</h2>
        {{dashChanges.changes}}
        </div>
      <div class="dash__box">
        <h2>Páginas</h2>
        {{dashChanges.total}}</div>
      <div class="dash__box">
        <h2>Média/Página</h2>
        {{dashChanges.media}}</div>
    </div>
    <div class="row justify-content-center">
        <h1>Edições por usuário</h1>
      </div>
      <div class="row">
        <div class="col-md-12">
          <Barchart
            v-if="usersChart"
            :chartData.sync="usersChart.line.data"
            :chartOptions.sync="usersChart.options"
            :styles.sync="usersChart.line.styles"
          />
        </div>
      </div>

      <div class="row justify-content-center">
        <h1>Matrículas editadas</h1>
      </div>

      <div class="row monitor-lists">
        <div class="col-md-12">
          <Barchart
            v-if="matriculaChart"
            :chartData.sync="matriculaChart.line.data"
            :chartOptions.sync="matriculaChart.options"
            :styles.sync="matriculaChart.line.styles"
          />
        </div>
        <div class="col-md-12">
          <ListAnalyticsClassification
            v-if="matriculasCount.length > 0"
            :title="`Matrículas editadas - ${matriculasCount.length}`"
            :rows.sync="matriculasCount"
            :type="`Matriculas Editadas`"
            :updated="updated"
            :numberpages="5"
          />
        </div>
      </div>

      <div class="row justify-content-center">
        <h1>Lista de Edições</h1>
      </div>
      <div class="row">
        <div class="col-md-12">
          <Barchart
            v-if="classificationChart"
            :chartData.sync="classificationChart.line.data"
            :chartOptions.sync="classificationChart.options"
            :styles.sync="classificationChart.line.styles"
          />
        </div>
      </div>

      <div class="row searchBox">
        <div class="col-md-10">
          <input
            type="text"
            v-model="numbermatricula"
            placeholder="Digite o número da matrícula"
            @change="filterMatricula(numbermatricula)"
          />
        </div>
        <div class="col-md-2">
          <button @click="resetMatricula()">Reset</button>
        </div>
      </div>

      <div class="row matriculasBox">
        <ListObjects
          v-if="changesBytype"
          :rows.sync="changesBytype"
          :updated.sync="updated"
          :numberpages="5"
        />
      </div>


    </div>

  </div>
</template>
<script type="text/javascript">
import { MatriculasMixin } from "./Matriculas.mixin.js";
import Barchart from "../../components/ui/Barchart/Barchart";
import ListObjects from "../../components/ui/list-objects/list-objects.vue";
import ListAnalyticsClassification from "../../components/ui/list-analytics-classification/list-analytics-classification.vue";
import DateRangePicker from "vue2-daterange-picker";
import LoaderComponent from "../../components/ui/loader/loader";

//you need to import the CSS manually (in case you want to override it)
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  data() {
    return {};
  },
  watch: {},
  props: {},
  components: {
    Barchart,
    DateRangePicker,
    LoaderComponent,
    ListObjects,
    ListAnalyticsClassification
  },
  name: "Matriculas",
  mixins: [MatriculasMixin],
};
</script>
<style src="./Matriculas.less" lang="less" />
