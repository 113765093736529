<template>
    <div class="container fieldList">
      <template v-if="paginated !== ''">
        <div
        
        v-for="(mat, key) in paginated.data"
        :key="key"
      >
        <h2 @click="enableMat(key)">Matrícula {{ key }}</h2>
        <table
          class="table table-striped tableMats"
          :class="{
            enabled: paginated.data[key].enabled,
            disabled: !paginated.data[key].enabled,
          }"
          :id="key"
        >
          <thead>
            <tr>
              <th
                v-for="(names, key_) in paginated.data[key]['paragraph_merge']"
                :key="key_"
              >
                {{ key_ }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(list, key_) in paginated.data[key]" :key="key_">
              <template v-for="(lab, key_) in list">
                <td :key="key_">{{ lab }}</td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>


       <div class="row" v-if="paginationType === 'fast'">
        <div class="pagination">
          <a
            v-for="(page, key) in pages"
            :key="key"
            :class="`${page.status}`"
            @click="changePage(page.page)"
            >{{ page.page }}</a
          >
        </div>
      </div>
      <div class="row pages" v-if="paginationType === 'long'">
        <div class="col-md-2">
          <div class="pagination">
            <a :class="`${pages[0].status}`" @click="previousPage()">{{
              pages[0].page
            }}</a>
            <a :class="`${pages[1].status}`" @click="nextPage()">{{
              pages[1].page
            }}</a>
          </div>
        </div>
        <div class="col-md-2">
          <h3>{{ pageLongIndex }} de {{ totalPages }} páginas</h3>
        </div>
        <div class="col-md-4">
          <input
            class="form-group"
            type="text"
            v-model="searchPage"
            placeholder="Procurar página..."
            @change="inputChange(searchPage)"
          />
        </div>
      </div>
      
      </template>
    </div>

</template>

<script type="text/javascript">
import { ListObjectsMixin } from "./list-objects.mixin";
export default {
  methods: {},
  name: "ListObjects",
  mixins: [ListObjectsMixin],
};
</script>

<style src="./list-objects.less" lang="less" />
